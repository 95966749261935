import axios from '../../config/axios';

const apiGetBanner = async () => {

    try {
        const result = await axios.get(`/washer/banner/list`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiGetNews = async () => {

    try {
        const result = await axios.get(`/washer/mobile/news`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiGetOnProcess = async (userid) => {

    try {
        const result = await axios.get(`/washer/transaction/user?customer_id=${userid}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiGetBagFinal = async (t_id, userid) => {
    try {
        const result = await axios.put(`/washer/transaction/status?transaction_id=${t_id}&status_id=7&customer_id=${userid}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiRateWasher = async (t_id, rate) => {
    try {
        const result = await axios.put(`/washer/rating?transaction_id=${t_id}&rate=${rate}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}




export {
    apiGetBanner,
    apiGetNews,
    apiGetOnProcess,
    apiGetBagFinal,
    apiRateWasher
}