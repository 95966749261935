import React, { Component } from 'react';
import { Sidebar, Menu } from '../../components/Menu';
import 'antd/dist/antd.css';

import { apiHisCoupon } from '../../serveices/api/Coupon';
import Swal from "sweetalert2";
import moment from 'moment';
import 'moment/locale/th'


import './scss/historycoupon.scss'





class HistoryCoupon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listCoupon: []
        };
    }

    componentDidMount() {
        this.getListHis()
    }

    getListHis = async () => {
        let userid = localStorage.getItem("userid");
        let list = await apiHisCoupon(userid)
        if (list.status === 200) {
            this.setState({
                listCoupon: list.data.result
            })
        }
    }



    render() {



        return (
            <div className="cover-home">
                <div className="box-bar">
                    <Sidebar />
                </div>

                <div className="box-coupon">
                    <div className="br-top">
                        <div className="cov-coupon">
                            <div className="hd-coupon">
                                <h3 className="txt">
                                    ประวัติการใช้คูปอง</h3>
                                <hr />
                            </div>

                            <div className="cov-list">
                            <div className="list-my-coupon">
                                            {
                                                this.state.listCoupon && this.state.listCoupon.length ? this.state.listCoupon.map((v, i) => <div key={i} className="bx-grid">
                                                    <div className="icon-size">
                                                        {console.log(v)}
                                                        <img src={v.bag_size === "L" ? "/img/icon/twl.png" : "/img/icon/twm.png"} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="detail">
                                                        <p className="type-coupon">คูปอง Size {v.bag_size}</p>
                                                        <p className="time">{v.use_date ? "ใช้แล้ว"+ " " + moment(v.use_date).format("DD/MM/YYYY HH:mm") : "คูปองหมดอายุ" }
                                                        {/* เหลือระยะเวลา {moment(v.end_date).diff(moment(), "days")} วัน */}
                                                        </p>
                                                    </div>
                                                </div>) : null
                                            }

                                        </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="menu-bar">
                    <Menu />
                </div>
            </div>
        )
    }
}

export default HistoryCoupon;
