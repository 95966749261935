import React, { Component } from 'react';
import { Menu, Sidebar } from '../../components/Menu';

import { apiGetContact } from '../../serveices/api/Contact';

import './scss/contact.scss'








class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            line: "",
            tel: "",
            email: ""
        };
    }

    componentDidMount() {
        this.getContact();
    }

    getContact = async () => {
        let resContact = await apiGetContact()
        if (resContact && resContact.status) {
            if (resContact.status == 200) {
                this.setState({
                    line: resContact.data.result.lineid,
                    tel: resContact.data.result.tel,
                    email: resContact.data.result.email
                })
                // console.log(resContact)

            }
        }
    }




    render() {



        return (
            <div className="cover-contact">
                <div className="box-bar">
                    <Sidebar />
                </div>
                <div className="contact container">

                    <div className="box-list-contact">
                        <div className="logo-trendy">
                            <img src="/img/logo/logonew.png" alt="" className="img-fluid" />
                        </div>
                        <div className="text-des">
                            <p className="txt">แจ้งปัญหาการใช้งาน / ข้อเสนอแนะ</p>
                            <p className="txt">Line ID : @trendywash</p>
                        </div>
                        <div className="cov-btn-line">
                            <a href="https://line.me/R/ti/p/@trendywash" className="link-line">
                                <img src="/img/btn/line.png" alt="" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="menu-bar">
                    <Menu />
                </div>
            </div>
        )
    }
}

export default Contact;
