import React, { useEffect } from 'react';
import { checkPayment } from '../../serveices/api/Topup';
import 'antd/dist/antd.css';
import {
    LoadingOutlined
  } from '@ant-design/icons';
import './scss/loading.scss';
import { useParams } from "react-router-dom";



function Loading() {

    let { id } = useParams();

    useEffect(async () => {
        let res = await checkPayment(id)
        if (res && res.status) {
            console.log(res.data.status)
            if(res.data.status == true){
                window.location.href = "/home"
            }
        } else {
            console.log(res.message)
        }
    })


  return (

    <div className="bx-login-page">
        <div className="bx-logo">
            <img alt="logo" className="logo-trendy" src="/img/Logo/logo-login.png" />
        </div>
        <div className="loading-icon">
            <LoadingOutlined style={{fontSize: "100px", color: "white"}} />
        </div>
    </div>

  );
}
export default Loading