import axios from '../../config/axios';


const apiGetHistory = async (userid) => {

    try {
        const result = await axios.get(`/washer/history/user?customer_id=${userid}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


export {
    apiGetHistory
}