import React, { useState } from 'react';
import { Menu, Sidebar } from '../../components/Menu';
import { qrPayment, truePayment } from '../../serveices/api/Topup';
import 'antd/dist/antd.css';
import { Modal, Row, Col, Button, InputNumber } from 'antd';
import PullToRefresh from 'react-simple-pull-to-refresh';
import './scss/topup.scss';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useJwt } from "react-jwt"

var QRCode = require('qrcode.react');

function Topup() {
    const { decodedToken, isExpired } = useJwt(localStorage.getItem("token")); // TODO : check expire and redirect to login page
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalTrue, setIsModalTrue] = useState(false);
    const [isModalCredit, setIsModalCredit] = useState(false);
    const [isModalPolicy, setIsModalPolicy] = useState(false);
    const [qrcode, setQrCode] = useState("");
    const [amount, setAmount] = useState(0);
    const [loadingQr, setLoadingQr] = useState(false);
    const [loadingTrue, setLoadingTrue] = useState(false);
    const [loadingCredit, setLoadingCredit] = useState(false);
    const [imgDownload, setImgDownload] = useState(undefined);

    let history = useHistory()

    const downloadQR = () => {
        const canvas = document.getElementById("qr-img");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // setImgDownload(pngUrl);

    };


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setAmount(0)
    };

    const showModalTrue = () => {
        setIsModalTrue(true);
    };

    const handleCancelTrue = () => {
        setIsModalTrue(false);
        setAmount(0)
    };

    const showModalCredit = () => {
        setIsModalCredit(true);
    };

    const handleCancelCredit = () => {
        setIsModalCredit(false);
        setAmount(0)
    };

    const showModalPolicy = () => {
        setIsModalPolicy(true);
    };

    const handleCancelPolicy = () => {
        setIsModalPolicy(false);
    };


    const onChangeNum = (value) => {
        setAmount(Math.floor(value));
        setQrCode("");
    }

    const onChangeNumTrue = (value) => {
        console.log(value)
        value = value * 1.05;

        setAmount(value);
    }

    const payCredit = async () => {
        setLoadingCredit(true)
        let data = {
            amount: amount * 100,
            channel: "ktbcard",
            merchant_order_no: ""
        }
        let res = await truePayment(data)
        if (res && res.status) {
            setLoadingCredit(false)
            window.location.href = res.data.result.data.pay_content
        } else {
            setLoadingCredit(false)
            Swal.fire({
                title: 'Error!',
                text: res.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
            console.log(res)
        }
    }



    const payTruemoney = async () => {
        setLoadingTrue(true)
        let data = {
            amount: amount * 100,
            channel: "truemoney",
            merchant_order_no: ""
        }
        let res = await truePayment(data)
        if (res && res.status) {
            setLoadingTrue(false)
            window.location.href = res.data.result.data.pay_content
        } else {
            setLoadingTrue(false)
            Swal.fire({
                title: 'Error!',
                text: res.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
            console.log(res)
        }
    }

    const getQRPayment = async () => {
        setLoadingQr(true)
        let userid = localStorage.getItem("userid");
        let action = "scb";
        let res = await qrPayment(action, userid, amount)
        if (res && res.status) {
            console.log(res.data.qrcode)
            setLoadingQr(false)
            setQrCode(res.data.qrcode)
        } else {
            setLoadingQr(false)
            Swal.fire({
                title: 'Error!',
                text: res.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
            console.log(res.message)
        }
    }


    const handleRefresh = () => {
        window.location.reload(false);
    }

    return (
        <div className="bx-topup-page">
            <div className="box-bar">
                <Sidebar />
            </div>
            <PullToRefresh onRefresh={handleRefresh}>
                <div className="cov-topup">
                    <div className="title-page">
                        <img className="icon-title" alt="iconwallet" src="/img/icon/wallet.svg" />
                        <h3 className="title-name">ช่องทางการเติมเงิน</h3>
                        <hr className="gray" />
                    </div>
                    <div className="list-chanel">
                        <div className="bx-chanel" onClick={showModal}>
                            <div className="grid-cov">
                                <div className="bx-icon">
                                    <div className="cov-img">
                                        <img className="logo-chanel" alt="QRCODE" src="/img/Topup/qr.svg" />
                                    </div>
                                </div>
                                <div className="bx-name-chanel">
                                    <p className="chanel">เติมเงินผ่าน QR Code</p>
                                    <p className="fee">-- ฟรี ค่าธรรมเนียม --</p>
                                </div>
                            </div>
                        </div>

                        <div className="bx-chanel" onClick={showModalCredit}>
                            <div className="grid-cov">
                                <div className="bx-icon">
                                    <div className="cov-img">
                                        <img className="logo-chanel" alt="CREDIT" src="/img/Topup/credit.svg" />
                                    </div>
                                </div>
                                <div className="bx-name-chanel">
                                    <p className="chanel">เติมเงินผ่านบัตรเครดิต</p>
                                    <p className="fee">-- มีค่าธรรมเนียม 5% --</p>
                                </div>
                            </div>
                        </div>

                        <div className="bx-chanel" onClick={showModalTrue}>
                            <div className="grid-cov">
                                <div className="bx-icon">
                                    <div className="cov-img">
                                        <img className="logo-chanel" alt="TRUEMONEY" src="/img/Topup/truemoney@2x.png" />
                                    </div>
                                </div>
                                <div className="bx-name-chanel">
                                    <p className="chanel">เติมเงินผ่าน Truemoney</p>
                                    <p className="fee">-- มีค่าธรรมเนียม 5% --</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="policy-pay">
                        <p className="txt-policy" onClick={showModalPolicy}>นโยบายการชำระเงิน</p>
                    </div>
                </div>

                <Modal className="modal-topup" title="เติมเงินผ่าน QR Code" visible={isModalVisible} onCancel={handleCancel} footer={false}>
                    <p className="title-topup">จำนวนเงินที่ต้องการเติม</p>
                    <div className="cov-btn-num">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Button onClick={() => {
                                    setAmount(50);
                                    setQrCode("");
                                }} className="btn-num">50</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(100); setQrCode(""); }} className="btn-num">100</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(200); setQrCode(""); }} className="btn-num">200</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(300); setQrCode(""); }} className="btn-num">300</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(500); setQrCode(""); }} className="btn-num">500</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(1000); setQrCode(""); }} className="btn-num">1000</Button>
                            </ Col>

                        </Row>
                    </div>
                    <div className="bx-input-num">
                        <InputNumber min={10} max={5000} value={amount ? amount : ""} onChange={onChangeNum} className="input-num" />
                    </div>

                    {
                        qrcode ? <div className="cov-qr">
                            <QRCode id="qr-img" value={qrcode} className="qr-topup" />
                            <p className="txt-rec">กรุณาบันทึกภาพหน้าจอ (Capture)
                                และนำไปชำระผ่าน Mobile Banking
                                หากชำระแล้ว ระบบจะเพิ่มยอดเงินให้อัตโนมัติ.</p>
                            {/* <Button className="btn-save-qr" onClick={() => downloadQR()}>บันทึก QR Code</Button> */}
                        </div> :
                            <div className="cov-btn-topup">
                                <Button className="btn-cancel" onClick={handleCancel}>ยกเลิก</Button>
                                <Button className="btn-confirm" loading={loadingQr} onClick={getQRPayment}>ยืนยัน</Button>


                            </div>
                    }

                </Modal>


                <Modal className="modal-topup" title="เติมเงินผ่านบัตรเครดิต" visible={isModalCredit} onCancel={handleCancelCredit} footer={false}>
                    <p className="title-topup">จำนวนเงินที่ต้องการเติม</p>
                    <div className="cov-btn-num">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Button onClick={() => {
                                    setAmount(50 * 1.05);
                                }} className="btn-num">50</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(100 * 1.05); }} className="btn-num">100</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(200 * 1.05); }} className="btn-num">200</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(300 * 1.05); }} className="btn-num">300</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(500 * 1.05); }} className="btn-num">500</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(1000 * 1.05); }} className="btn-num">1000</Button>
                            </ Col>

                        </Row>
                    </div>
                    <div className="bx-input-num">
                        <InputNumber min={1} max={5000} value={amount ? amount : ""} onChange={value => onChangeNumTrue(value)} className="input-num" />
                    </div>

                    <div className="cov-btn-topup">
                        <Button className="btn-cancel" onClick={handleCancelCredit}>ยกเลิก</Button>
                        <Button className="btn-confirm" loading={loadingCredit} onClick={payCredit}>ยืนยัน</Button>
                    </div>

                </Modal>


                <Modal className="modal-topup" title="เติมเงินผ่าน Truemoney" visible={isModalTrue} onCancel={handleCancelTrue} footer={false}>
                    <p className="title-topup">จำนวนเงินที่ต้องการเติม</p>
                    <div className="cov-btn-num">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Button onClick={() => {
                                    setAmount(50 * 1.05);
                                }} className="btn-num">50</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(100 * 1.05); }} className="btn-num">100</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(200 * 1.05); }} className="btn-num">200</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(300 * 1.05); }} className="btn-num">300</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(500 * 1.05); }} className="btn-num">500</Button>
                            </ Col>
                            <Col span={8}>
                                <Button onClick={() => { setAmount(1000 * 1.05); }} className="btn-num">1000</Button>
                            </ Col>

                        </Row>
                    </div>
                    <div className="bx-input-num">
                        <InputNumber min={1} max={5000} value={amount ? amount : ""} onChange={value => onChangeNumTrue(value)} className="input-num" />
                    </div>

                    <div className="cov-btn-topup">
                        <Button className="btn-cancel" onClick={handleCancelTrue}>ยกเลิก</Button>
                        <Button className="btn-confirm" loading={loadingTrue} onClick={payTruemoney}>ยืนยัน</Button>
                    </div>

                </Modal>

                <Modal className="modal-policy" title="นโยบายการชำระเงิน" visible={isModalPolicy} footer={false} onCancel={handleCancelPolicy}>
                    <h5 className="title-modal-policy">นโยบายการชำระเงิน</h5>
                    <p className="raw-policy">คุณสามารถชำระเงินเติมเครดิตผ่านช่องทางQR code  ธนาคาร , บัตรเครดิต และ ทรูมันนี่ โดยการชำระเงินจะมีผลทันที</p>
                    <br />
                    <h5 className="title-modal-policy">เงื่อนไขการขอเงินคืน</h5>
                    <p className="raw-policy">1.หากเกิดข้อผิดพลาดของการทำงานของระบบ เนื่องจากระบบชำรุดเสียหาย หรือเครื่องซักผ้า อบผ้า ชำรุดขณะใช้งาน ซึ่งไม่ได้เกิดจากมือถือของลูกค้าเอง โดยท่านสามารถแจ้งทางเมนู Contact</p>
                    <p className="raw-policy">2.หากระบบตรวจสอบแล้วว่าเกิดความผิดพลาดจากระบบ คุณจะได้รับเครดิตคืนเพื่อใช้บริการครั้งต่อไป โดยคุณจะได้รับข้อความยืนยันการคืนเครดิตทาง Notification</p>
                    {/* <p className="raw-policy">3.ถ้าท่านย้ายที่พักอาศัย และไม่สามารถใช้ระบบ Trendywash ได้ ท่านสามารถติดต่อขอเงินคืนได้ผ่านทาง Call center ของ Trendy Wash </p> */}
                    <p className="raw-policy">3.หากระบบ Trendy Wash ที่ท่านใช้งานอยู่ ได้ถูกยกเลิกให้บริการ Trendy Wash ท่านสามารถติดต่อขอเงินคืนได้ผ่านทาง Call Center ของ Trendywash </p>
                </Modal>




            </PullToRefresh>
            <Menu />

        </div>
    );
}
export default Topup