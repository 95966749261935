import axios from '../../config/axios';

const apiUserAction = async (data) => {

    try {
        const result = await axios.post(`/washer/transaction/user`
              , data
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiRegisterBag = async (data) => {

    try {
        const result = await axios.post(`/washer/bag/register`
              , data
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiListSite = async () => {
    try {
        const result = await axios.get(`/washer/site/dropdown`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiGetPrice = async (bag,userid) =>{
    try {
        const result = await axios.get(`/washer/checkout/user?bag_name=${bag}&customer_id=${userid}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiGetMyBag = async (customer_id) => {
    try {
        const result = await axios.get(`/washer/bag/dropdown?customer_id=${customer_id}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

export {
    apiUserAction,
    apiRegisterBag,
    apiListSite,
    apiGetPrice,
    apiGetMyBag
}