import React, { Component } from 'react';
import QrReader from 'react-qr-reader'
import jwt_decode from "jwt-decode";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  Select,
  Radio,
  Space,
  Input,
  message
} from 'antd';
import { Menu, Sidebar } from '../../components/Menu';
import Swal from "sweetalert2";
import { apiUserAction, apiListSite, apiGetPrice, apiGetMyBag } from '../../serveices/api/Scan';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons";

import moment from 'moment';
import 'moment/locale/th'

import 'antd/dist/antd.css';
import './scss/scanner.scss'

library.add(fas);
const { Option } = Select;
const { TextArea } = Input;


class Scanner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // result: 'No result'
      isModalVisible: false,
      coupon: 0,
      bagName: "",
      siteId: 0,
      comment: "",
      siteList: [],
      price: 0,
      is_coupon: false,
      coupon_id: 0,
      inputBag: "",
      myBagList: [],
      myCouponList: []
    };

    // this.openImageDialog = this.openImageDialog.bind(this)

  }

  componentDidMount() {
    this.getMyBag();
    let site_id = new URLSearchParams(this.props.location.search).get("site_id")
    if (site_id != "null") {
      this.setState({
        siteId: site_id
      })
    }

  }

  getMyBag = async () => {
    let userid = localStorage.getItem("userid");
    let list = await apiGetMyBag(userid)
    if (list.status === 200) {
      this.setState({
        myBagList: list.data.result,
      })
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'กรุณาลองใหม่อีกครั้ง',
        icon: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }

  onChangeRadio = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      coupon: e.target.value,
    });
  }

  handleScan = async (data) => {
    let userid = localStorage.getItem("userid")
    if (this.state.inputBag != null) {


      let price = await apiGetPrice(this.state.inputBag, userid)
      if (price.status === 200) {
        this.setState({
          price: price.data.result.price,
          is_coupon: price.data.result.is_coupon,
          myCouponList: price.data.result.coupon_list,
          coupon_id: price.data.result.coupon_id
        })
      }


      let list = await apiListSite()
      if (list.status === 200) {
        this.setState({
          siteList: list.data.result,
          isModalVisible: true,
          bagName: data
        })
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000
        })
      }

    }
  }


  onChangeSelect = (e) => {
    console.log(`selected ${e}`);
    this.setState({
      siteId: e
    })
  }



  backToHome = () => {

    this.props.history.push({
      pathname: '/home',
    })

  }


  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  };

  messageWarning = () => {
    message.warning(
      'กรุณาเลือกสาขาที่ต้องการจะส่งผ้า',
      3
    );
  };

  handleOk = async () => {
    if (this.state.siteId === 0) {
      this.messageWarning()
    } else {
      let data = {
        bag_name: this.state.inputBag,
        customer_id: parseInt(localStorage.getItem("userid")),
        site_id: parseInt(this.state.siteId),
        coupon_id: this.state.coupon,
        comment: this.state.comment
      }
      let resScan = await apiUserAction(data)
      if (resScan && resScan.status) {
        if (resScan.status == 200) {
          Swal.fire({
            title: 'Success!',
            text: 'ส่งผ้าสำเร็จ',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })

          setTimeout(() => {
            window.location.href = "/home"
          }, 1500)
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: resScan.message,
          icon: 'error',
          showConfirmButton: false,
          timer: 3000
        })
      }
    }
    this.setState({
      isModalVisible: false
    })
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };

  onChangeTextArea = e => {
    console.log('Change:', e.target.value);
    this.setState({
      comment: e.target.value
    })
  };


  // openImageDialog() {
  //   this.refs.qrReader1.openImageDialog()
  // }


  handleError = err => {
    console.error(err)
  }

  onChangeSelectBag = (e) => {
    console.log(`selected ${e}`);
    this.setState({
      inputBag: e
    })
  }



  render() {


    return (
      <div className="cover-qrcode">
        <div className="box-bar">
          <Sidebar />
        </div>

        {/* <div className="btn-back">
          <Button onClick={() => { this.backToHome() }} className="btn-ex">Back</Button>
        </div> */}

        <div style={{textAlign: "center",margin: "15px 0"}} className="dropdown-site">
          <Select
            showSearch
            style={{ width: "80%", margin: "auto" }}
            placeholder="โปรดถุงเลือกที่ต้องการส่ง"
            value={this.state.inputBag ? this.state.inputBag : null}
            defaultValue={this.state.inputBag ? this.state.inputBag : null}
            optionFilterProp="children"
            onChange={(e) => this.onChangeSelectBag(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              this.state.myBagList ? this.state.myBagList.map((v, i) => <Option key={i} value={v.bag_name}>{v.bag_name}</Option>) : null
            }
          </Select>
        </div>

        {/* <div className="bx-data">
          <Input onChange={(e) => { this.setState({ inputBag: e.target.value }) }} style={{ width: "80%", margin: "auto" }} value={this.state.inputBag ? this.state.inputBag : ""} placeholder="รหัสถุง" />
        </div> */}
        <div className="bx-btn">
          <Button className="btn-submit" onClick={() => this.handleScan()}>ส่งผ้า</Button>
        </div>


        {/* <div className="box-qr-scan"> */}
        {/* <QrReader
            ref="qrReader1"
            delay={500}
            onError={this.handleError}
            onScan={this.handleScan}
            // legacyMode
            className="box-qr"
          /> */}

        {/* <input type="button" value="Submit QR Code" onClick={this.openImageDialog} /> */}
        {/* <p className="txt-des">สแกน QR Code ที่ถุงผ้าเพื่อส่งผ้า</p>
        </div> */}
        <div className="bx-sale-coupon">
          <div className="slide-coupon">

          </div>
        </div>
        <div className="menu-bar">
          <Menu />
        </div>


        <Modal title="คุณต้องการจะส่งผ้า?"
          className="modal-send"
          visible={this.state.isModalVisible}
          footer={false}
          onOk={() => this.handleOk()} onCancel={() => this.handleCancel()}>
          <div className="dropdown-site">
            {console.log('id', this.state.siteId)}
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="โปรดเลือกสาขาที่ต้องการ"
              value={this.state.siteId ? parseInt(this.state.siteId) : null}
              defaultValue={(this.state.siteId != 0 || this.state.siteId != null) ? this.state.siteId : null}
              optionFilterProp="children"
              onChange={(e) => this.onChangeSelect(e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                this.state.siteList ? this.state.siteList.map((v, i) => <Option key={i} value={v.site_id}>{v.site_name}</Option>) : null
              }
            </Select>
          </div>
          <div className="use-coupon">
            <p onClick={() => { window.location.href = "/coupon" }} className="title-coupon">ใช้คูปอง</p>
            <div className="bx-radio">
              <Radio.Group onChange={this.onChangeRadio} value={this.state.coupon ? this.state.coupon : 0}>
                <Space direction="vertical">
                  <Radio value={0}>ไม่ใช้คูปอง</Radio>
                  {
                    this.state.is_coupon ? this.state.myCouponList.map((v,i)=><Radio key={i} value={v.coupon_id}> คูปอง Size {v.bag_size} เหลือเวลา {moment(v.end_date).diff(moment(), "days")} วัน</Radio>) : null
                  }
                </Space>
              </Radio.Group>
            </div>
          </div>
          <div className="bx-comment">
            <TextArea placeholder="Comment" value={this.state.comment ? this.state.comment : ""} maxLength={100} onChange={this.onChangeTextArea} />
          </div>
          <p className="total">{this.state.coupon ? "ใช้คูปอง" : "ราคา"+" "+ (this.state.price ? this.state.price : 0) + " " + "บาท"}</p>
          <div className="cov-btn-action">
            <Button className="btn-cancel"
              onClick={() => this.handleCancel()}
            >ยกเลิก</Button>
            <Button className="btn-ok"
              onClick={() => this.handleOk()}
            >ยืนยัน</Button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Scanner;
