import axios from '../../config/axios';


const apiLogin = async (data) => {

    try {
        const result = await axios.post(`/mobile/member_login.php`, data
            //   ,{headers: {
            //     Authorization: "Bearer " + localStorage.getItem("token"),
            //   }}
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiRegister = async (data) => {

    try {
        const result = await axios.post(`/public/customer/register`, data
            //   ,{headers: {
            //     Authorization: "Bearer " + localStorage.getItem("token"),
            //   }}
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiForgetPassword = async (data) => {

    try {
        const result = await axios.post(`/public/customer/forgetPassword`, data
            //   ,{headers: {
            //     Authorization: "Bearer " + localStorage.getItem("token"),
            //   }}
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

export {
    apiLogin,
    apiRegister,
    apiForgetPassword
}