import React, { Component } from 'react';
import { Sidebar, Menu } from '../../components/Menu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Upload, message, Form, Input, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Swal from "sweetalert2";

import { apiGetProfile, apiEditProfile } from '../../serveices/api/Profile';

import 'antd/dist/antd.css';
import './scss/profile.scss';

library.add(fas);



const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    // return isJpgOrPng && isLt2M;
    return false;
}


class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            editable: true,
            imageUrl: [],
            nameuser: "",
            email: "",
            phone: "",
            changeImg: [],
            credit: 0,
            birthday: "",
            gender: "",
            career: "",
            line_id: ""
        };
    }


    componentDidMount() {
        this.getProfile();
    }

    getProfile = async () => {
        let userid = localStorage.getItem("userid")
        let resProfile = await apiGetProfile(userid)
        if (resProfile && resProfile.status) {
            console.log(resProfile)
            if (resProfile.status == 200) {
                this.setState({
                    imageUrl: resProfile.data.result.customer_img,
                    nameuser: resProfile.data.result.customer_name,
                    email: resProfile.data.result.customer_email,
                    phone: resProfile.data.result.customer_tel,
                    credit: resProfile.data.result.credit,
                    birthday: resProfile.data.result.customer_birthday,
                    gender: resProfile.data.result.customer_gender,
                    career: resProfile.data.result.customer_career,
                    line_id: resProfile.data.result.customer_line_id
                })

            }
        }
    }


    handleChange = info => {

        setTimeout(() => {
            const isLt2M = info.file.size / 1024 / 1024 < 2;

            if (
                isLt2M &&
                (info.file.type === "image/jpeg" ||
                    info.file.type === "image/png" ||
                    info.file.type === "image/jpg")
            ) {
                info.file.status = "done";
            } else {
                info.file.status = "error";
            }

            if (info.file.status === 'uploading') {
                this.setState({ loading: true });
                return;
            }

            if (info.file.status === "done") {
                info.fileList.map((image) => {
                    if (image.uid === info.file.uid) {
                        info.file.originFileObj = image.originFileObj;
                    }
                });
                this.setState({
                    LogoStation: info.file.originFileObj,
                });
                getBase64(info.file.originFileObj, (imageUrl) =>
                    this.setState({
                        changeImg: info.file.originFileObj,
                        imageUrl,
                        loading: false,
                    })
                );
            } else {
                this.setState({
                    loading: false,
                });
            }

        }, 1000);
    };




    enabledEdit = () => {
        this.setState({
            editable: false
        })
    };


    cancelUpdate = () => {

        this.getProfile();
        this.setState({
            editable: true
        })


    }


    editProfile = () => {
        this.saveEdit();
    }

    saveEdit = async () => {
        let userid = parseInt(localStorage.getItem("userid"));
        let data = {
            customer_id: userid,
            customer_name: this.state.nameuser,
            customer_tel: this.state.phone,
            customer_email: this.state.email,
            customer_birthday: this.state.birthday,
            customer_gender: this.state.gender,
            customer_career: this.state.career,
            customer_line_id: this.state.line_id
        }

        let resEdit = await apiEditProfile(data)
        if (resEdit && resEdit.status) {
            // console.log(resEdit)
            if (resEdit.status == 200) {
                Swal.fire({
                    title: 'Succcess!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })

                this.setState({
                    editable: true
                })


                this.getProfile();
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: resEdit.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
        }

    }


    render() {


        const { loading, imageUrl } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );


        return (
            <div className="cover-profile">
                <div className="box-bar">
                    <Sidebar />
                </div>
                <div className="box-profile">
                    {/* <div className="bx-hd-title">
                        <h3 className="title">Profile</h3>
                    </div> */}
                    <div className="form-profile container">
                        <div className="box-btn-edit">
                            <Button className="btn-edit" onClick={() => { this.enabledEdit() }}><FontAwesomeIcon icon={['fas', 'edit']} /></Button>
                        </div>
                        <div className="img-profile">
                            <Upload
                                accept=".png, .jpg, .jpeg"
                                name="avatar"
                                disabled={this.state.editable}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload}
                                onChange={this.handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </div>
                        <div className="bx-credit">
                            <p className="credit">Credit: {this.state.credit}</p>
                        </div>
                        <div className="form-info">
                            <Form className="profile-data" {...layout}
                                name="nest-messages"
                            >
                                <div className="bx">
                                    <Input
                                        onChange={(e) => { this.setState({ nameuser: e.target.value }) }}
                                        value={this.state.nameuser ? this.state.nameuser : ""}
                                        disabled={this.state.editable} />
                                </div>

                                <div className="bx">
                                    <Input
                                        onChange={(e) => { this.setState({ email: e.target.value }) }}
                                        value={this.state.email ? this.state.email : null}
                                        disabled={this.state.editable} />
                                </div>


                                <div className="bx">
                                    <Input
                                        onChange={(e) => { this.setState({ phone: e.target.value }) }}
                                        value={this.state.phone ? this.state.phone : null}
                                        maxLength={10}
                                        disabled={this.state.editable} />
                                </div>

                                <div className="btn">
                                    {this.state.editable ? null : <div>
                                        <Button onClick={() => { this.editProfile() }} className="btn-edit-profile" type="primary">Submit</Button>
                                        <Button onClick={() => { this.cancelUpdate() }} className="btn-cancel-profile" type="primary">Cancel</Button>
                                    </div>}
                                </div>




                            </Form>
                        </div>
                    </div>
                </div>
                <div className="menu-bar">
                    <Menu />
                </div>
            </div>
        )
    }
}

export default Profile;
