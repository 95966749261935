import axios from '../../config/axios';


  const qrPayment = async (action,userid,amount) => {
    try {
      const result = await axios.get(`https://trendywash.net/api/v1/mobile/member_payment?action=${action}&customer_id=${userid}&amount=${amount}`)
      return result
    } catch (error) {
      return error && error.response && error.response.data
    }
  }


  const truePayment = async (data) => {
    try {
      const result = await axios.post(`/private/ksher/payTransaction`,data,{headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }})
      return result
    } catch (error) {
      return error && error.response && error.response.data
    }
  }

  const checkPayment = async (mch_order_no) => {
    try {
      const result = await axios.get(`/private/ksher/get?mch_order_no=${mch_order_no}`,{headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }})
      return result
    } catch (error) {
      return error && error.response && error.response.data
    }
  }


  export {
    qrPayment,
    truePayment,
    checkPayment
  }