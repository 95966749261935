import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './scss/menu.scss'





class Menu extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }



  render() {



    return (
      <div>
        <div className="cover-menu">
          <div className="menu-ta">
            <div onClick={() => { window.location.href = "/home" }} className="bx-menu">
              <div className="img-menu">
                <img src="/img/icon/h.png" alt="" className="img-fluid" />
              </div>
              <p className="name-menu">Home</p>
            </div>
            <div onClick={() => { window.location.href = "/coupon" }} className="bx-menu">
              <div className="img-menu">
                <img src="/img/icon/c.png" alt="" className="img-fluid" />
              </div>
              <p className="name-menu">Coupon</p>
            </div>
            <div onClick={() => { window.location.href = "/scanner" }} className="bx-menu">
              <div className="img-menu">
                <img src="/img/icon/s.png" alt="" className="img-fluid qr" />
              </div>
              <p className="name-menu">Washer</p>
            </div>

            <div onClick={() => { window.location.href = "/topup" }} className="bx-menu">

            {/* <div onClick={() => { window.location.href = "https://trendywash.net/webapp/app/payment.php" }} className="bx-menu"> */}
              <div className="img-menu">
                <img src="/img/icon/t.png" alt="" className="img-fluid" />
              </div>
              <p className="name-menu">Topup</p>
            </div>
            <div onClick={() => { window.location.href = "/submenu" }} className="bx-menu">
              <div className="img-menu">
                <img src="/img/icon/p.png" alt="" className="img-fluid" />
              </div>
              <p className="name-menu">Profile</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Menu;
