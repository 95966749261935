import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './scss/navbar.scss'






class Navbar extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }


 logOut = () => {
  localStorage.removeItem("userid");
    setTimeout(() => { window.location.href = "/" }, 300)
 }

  render() {



    return (
      <div>
        <div className="cover-navbar">
          <nav className="navbar navbar-expand-lg navbar-light menu-nav">
            <a style={{fontWeight: "bold"}} className="navbar-brand" href="#">Express</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item active">
                  <a className="nav-link" href="/home">Home <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/history">History</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/scanner">Scan QR</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/topup">Topup</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/profile">Profile</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">Contact</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={()=> {
                    this.logOut();
                  }}>Logout</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Navbar;
