import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import QrReader from 'react-qr-reader';
import { Menu, Sidebar } from '../../components/Menu';
import { apiRegisterBag } from '../../serveices/api/Scan';
import Swal from "sweetalert2";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
    Input,
    Button,
    Modal,
    message
} from 'antd';
import 'antd/dist/antd.css';

import './scss/registerbag.scss'

library.add(fas);



const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};


class RegisterBag extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            bagCode: ""

        };
    }


    componentDidMount() {

    }


    handleScan = value => {
        if (value) {
            this.setState({
                isModalVisible: false,
                bagCode: value
            })
        }
    }

    messageWarning = () => {
        message.warning(
            'กรุณาสแกนรหัสถุง',
            3
        );
    };


    register = async () => {

        if (this.state.bagCode === "") {
            this.messageWarning()
        } else {
            let userid = localStorage.getItem("userid");
            let data = {
                bag_name: this.state.bagCode,
                customer_id: parseInt(userid)
            }

            


            let regis = await apiRegisterBag(data)
            console.log(typeof customer_id)
            if (regis.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'ลงทะเบียนถุงสำเร็จ',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })

                setTimeout(() => { window.location.href = "/home" }, 1500)
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: regis.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }



    }





    onFinish = (values) => {
        console.log('Success:', values);
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render() {



        return (
            <div className="cover-register">
                <div className="box-bar">
                    <Sidebar />
                </div>

                <div className="container box-page-regis">
                    <div className="po-ab">
                        <div className="hd-register">
                            <h1 className="txt-hd"><FontAwesomeIcon icon={['fas', 'shopping-bag']} /> Registeration Bag</h1>
                            <hr className="br-hd" />
                        </div>
                        <div className="box-form-data">
                            <div className="fill-data">

                                <div className="bx-data">
                                    <Input onChange={(e) => { this.setState({ bagCode: e.target.value }) }} style={{ width: "70%" }} value={this.state.bagCode ? this.state.bagCode : ""} placeholder="รหัสถุง" />
                                    <Button onClick={() => this.setState({ isModalVisible: true })} className="btn-scan">Scan</Button>
                                </div>

                                <p className="re-con">ยืนยันที่จะลงทะเบียนถุง?</p>

                                <div className="box-btn">
                                    <Button className="btn-regis" onClick={() => this.register()}>ยืนยัน</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-bar">
                    <Menu />
                </div>

                <Modal
                    title="กรุณาสแกน QRCODE ที่ถุงผ้า"
                    visible={this.state.isModalVisible}
                    closable={false}
                    footer={false}
                    centered={true}
                    className="modal-scan"
                >
                    <div className="f-c-c">
                        <QrReader
                            className="coin-collec-qr-reader"
                            delay={500}
                            onScan={value => this.handleScan(value)}
                            onError={err => console.error('scan with error :', err.message)}
                            facingMode="environment"
                        />
                    </div>
                    <div className="btn-cancel"
                        onClick={() => {
                            this.setState({
                                isModalVisible: false
                            })
                        }}
                    >Cancel</div>
                </Modal>
            </div>
        )
    }
}

export default RegisterBag;
