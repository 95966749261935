import React, { Component } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import {
    Form,
    Input,
    Button,
} from 'antd';
import 'antd/dist/antd.css';
import { apiRegister } from '../../serveices/api/Login';

import './scss/register.scss'

library.add(fas);



const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };


class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }





    onFinish = (values) => {
        let data = {
            "name": values.name,
            "tel": values.telephone,
            "email": values.email,
            "password": values.password
        }

        this.registerUser(data);
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    registerUser = async (data) => {
        // console.log(data)
        let resregis = await apiRegister(data)
        if (resregis && resregis.status) {
            console.log(resregis)
            if (resregis.status == 201) {
                Swal.fire({
                    title: 'Succcess!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                
                setTimeout(()=> {window.location.href = "/"},1000)
                
            } 
        }else{
            Swal.fire({
                title: 'Error!',
                text: resregis.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
        }
    
    }

    render() {



        return (
            <div className="cover-register">
                <div className="container box-page-regis">
                    <div className="hd-register">
                        <h1 className="txt-hd"><FontAwesomeIcon icon={['fas', 'user-plus']} /> Register</h1>
                        <hr className="br-hd" />
                    </div>
                    <div className="box-form-data">
                        <div className="fill-data">
                            <Form
                                {...layout}
                                name="basic"
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Name!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: 'Please input your email!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="TelePhone"
                                    name="telephone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your telephone!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>


                                <Form.Item
                                label="Re-type Password"
                                name="retypepassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Re-type Password!',
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                          return Promise.resolve();
                                        }
                          
                                        return Promise.reject('The two passwords that you entered do not match!');
                                      },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                                <Form.Item className="box-btn">
                                    <Button className="btn-regis" type="primary" htmlType="submit">Submit</Button>
                                    <a href="/" className="link">Sing in</a>
                                </Form.Item>

                            </Form>

                           
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;
