import axios from '../../config/axios';

const apiGetContact = async () => {

    try {
        const result = await axios.get(`/public/contact/get`
              ,{headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              }}
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

export {
    apiGetContact
}