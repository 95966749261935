import React, { Component } from 'react';
import { apiGetNews } from '../../serveices/api/Banner';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';

import './scss/announce.scss'

var url_img = "https://backoffice.trendywash.net";
class Announce extends Component {

    constructor(props) {
        super(props);

        this.state = {
            news: []
        };
    }


    componentDidMount() {
        this.getNews();
    }


    getNews = async () => {
        let resNews = await apiGetNews(1, 1, "")
        console.log(resNews)
        if (resNews && resNews.status) {
            if (resNews.status == 200) {
                this.setState({
                    news: resNews.data.result
                })

            }
        }
    }

    render() {



        return (
            <div className="cover-announce">
                <div className="box-announces">
                    <Carousel className="ann-slide" autoplay>

                        {
                            this.state.news ? this.state.news.map((item, index) => <div key={index} className="news-bx">
                                <div className="bx-ann">
                                    <img src={item.news_img ? url_img+item.news_img : "/img/icon/ann.png"} alt="" className="img-fluid img-ann" />
                                </div>
                                <div className="txt-news">
                                    <p className="title-news">{item.news_title ? item.news_title : ""}</p>
                                    <p className="des-news">{item.news_desciption ? item.news_desciption : ""}</p>
                                </div>
                            </div>) : null
                        }


                    </Carousel>



                </div>
            </div>
        )
    }
}

export default Announce;
