import React, { Component } from 'react';
import { apiGetProfile } from '../../serveices/api/Profile';
import "antd/dist/antd.css";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./scss/sidebar.scss";
// import { useTranslation } from "react-i18next";

// import { UserContext } from "../../contexts/userContext";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: [],
      nameuser: "",
      email: "",
      phone: "",
      credit: 0,
      point: 0,
      visible: false
    };
  }
  // const [visible, setVisible] = useState(false);
  // const history = useHistory();

  // const { t } = useTranslation();

  // const { userState, dispatch } = useContext(UserContext);

  

  // const handleClick = () => {
  //   history.push("/profile");
  // };

  // useEffect(() => {
  //   dataProfile();
  // }, [userid]);

  componentDidMount() {
    this.getProfile();
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  };

  onClose = () => {
    this.setState({
      visible: false
    })
  };

  getProfile = async () => {
    let userid = localStorage.getItem("userid")
    let resProfile = await apiGetProfile(userid)
    if (resProfile && resProfile.status) {
      if (resProfile.status == 200) {
        this.setState({
          imageUrl: resProfile.data.result.customer_img,
          nameuser: resProfile.data.result.customer_name,
          email: resProfile.data.result.customer_email,
          phone: resProfile.data.result.customer_tel,
          credit: resProfile.data.result.credit,
          point: resProfile.data.result.point
        })

      }
    }
  }

  logOut = () => {
    localStorage.removeItem("userid");
    setTimeout(() => { window.location.href = "/" }, 300)
  }
  render() {

    return (
      <div className="bx-header">
        <div className="cov-data">
          <div className="bx-btn-burger">
            <Button className="btn-toggle" onClick={()=>this.showDrawer()}>
              <MenuOutlined />
            </Button>
          </div>
          <div className="cov-logo">
            <img
              className="img-logo"
              alt="logo-menu"
              src="/img/logo/logo-login.png"
            />
          </div>
          <div className="point-credit">
            <p className="txt-credit">
              Credit : {this.state.credit ?  this.state.credit : 0}
            </p>
            <p className="txt-credit">
              Point : {this.state.point ?  this.state.point : 0}
            </p>
          </div>
        </div>

        <Drawer
          title=""
          placement="left"
          closable={false}
          onClose={()=>this.onClose()}
          visible={this.state.visible}
          className="bar-toggle"
        >
          <div className="cov-btn">
            <Button onClick={()=>this.onClose()} className="btn-tog-menu">
              <MenuOutlined />
            </Button>
          </div>
          <div className="bx-menu">
            <div className="member-card">
              <div className="box-member">
                <div className="bx-logo">
                  <img
                    className="logo-member"
                    alt="logocard"
                    src="/img/logo/logo-member.png"
                  />
                </div>
                <p className="name-user">
                  {this.state.nameuser ? this.state.nameuser
                    : "-- No Customer Name --"}
                </p>
              </div>
            </div>
            <div className="short-profile">
              <div className="grid-profile">
                <div className="img-profile">
                  <img
                    className="img-pro"
                    alt="imageprofile"
                    src={
                      this.state.imageUrl ? this.state.imageUrl
                        : "/Img/image.png"
                    }
                  />
                  {/* <Button className="btn-edit" onClick={handleClick}>
                    <img
                      className="icon-edit"
                      alt="iconedit"
                      src="/Img/Icon/settings.svg"
                    />
                  </Button> */}
                </div>
                <div className="detail-profile">
                  <p className="name-user">
                    {this.state.nameuser ? this.state.nameuser
                      : "-- No Customer Name --"}
                  </p>
                  <div className="contact-user">
                    <p className="tel">
                      {this.state.phone ? this.state.phone
                        : "-- No Customer Tel --"}
                    </p>
                    <p className="email">
                      {this.state.email ? this.state.email
                        : "-- No Customer Email --"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-submenu">
              <div className="menu">
                <a className="link-menu" href="/home">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/home.png"
                      />
                    </div>
                    <p className="sub-menu-name">หน้าหลัก</p>
                  </div>
                </a>
              </div>

              <div className="menu">
                <a className="link-menu" href="/profile">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/profile.png"
                      />
                    </div>
                    <p className="sub-menu-name">โปรไฟล์</p>
                  </div>
                </a>
              </div>



              <div className="menu">
                <a className="link-menu" href="/history">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/history.png"
                      />
                    </div>
                    <p className="sub-menu-name">ประวัติการใช้งาน</p>
                  </div>
                </a>
              </div>

              <div className="menu">
                <a className="link-menu" href="/coupon">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/coupon.png"
                      />
                    </div>
                    <p className="sub-menu-name">คูปอง</p>
                  </div>
                </a>
              </div>

              <div className="menu">
                <a className="link-menu" href="/coupon/history">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/coupon.png"
                      />
                    </div>
                    <p className="sub-menu-name">ประวัติการใช้คูปอง</p>
                  </div>
                </a>
              </div>

              <div className="menu">
                <a className="link-menu" href="/contact">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/help.png"
                      />
                    </div>
                    <p className="sub-menu-name">ติดต่อเรา</p>
                  </div>
                </a>
              </div>

              <div className="menu">
                <a className="link-menu" href="https://new.trendywash.net/home">
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/logo/logo-member.png"
                      />
                    </div>
                    <p className="sub-menu-name">Trendywash</p>
                  </div>
                </a>
              </div>



              {/* <div className="menu">
                <div className="link-menu" onClick={() => {this.logOut()}}>
                  <div className="grid-menu">
                    <div className="icon-sub">
                      <img
                        className="icon-mini-menu"
                        alt="imagemenu"
                        src="/img/icon/logout.png"
                      />
                    </div>
                    <p className="sub-menu-name">ออกจากระบบ</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </Drawer>
      </div>
    )

  }

}
export default Sidebar;
