import React, { Component } from 'react';
import { Sidebar, Menu } from '../../components/Menu';
import { Pagination } from 'antd';

import moment from 'moment';
import 'moment/locale/th'

import { apiGetHistory } from '../../serveices/api/History';
import 'antd/dist/antd.css';
import './scss/history.scss'








class History extends Component {

    constructor(props) {
        super(props);

        this.state = {
            result_list: [],
        };
    }
    componentDidMount() {
        this.getHistory()
    }


    getHistory = async () => {
        let userid = localStorage.getItem("userid");
        let resHistory = await apiGetHistory(userid)
        console.log(resHistory)
        if (resHistory && resHistory.status) {
            if (resHistory.status == 200) {

                // let i = 1;
                // const results = resHistory.data.result.map(row => ({
                //     key: i++, // I added this line
                //     date: row["last_update"],
                //     site: row["site_name"],
                //     price: row["price"],
                //     task_id: row["task_id"],
                //     washer_name: row['washer_name']

                // }))

                this.setState({
                    result_list: resHistory.data.result
                })
            }
        }
    }



    changePage = async (page, sizePage) => {
        // console.log(page,sizePage)
        await this.setState({
            current: page,
            limit: sizePage
        })

        this.getHistory();
    }



    render() {


        return (
            <div className="cover-history">
                <div className="box-bar">
                    <Sidebar />
                </div>
                <div className="contact">

                    <div className="br-bx">
                        <div className="br-his">
                            <div className="bx-hd-title">
                                <h3 className="title">ประวัติ</h3>
                            </div>
                            <hr className="br-title" />
                            <div className="box-table container">
                                {/* <div className="li-his">
                                    <div className="grid-his">
                                        <p className="site-used">The Privacy Sukhumvit 101</p>
                                        <p className="bag-send">S00000001</p>
                                    </div>
                                    <div className="grid-washer">
                                        <p className="washer-name">สุชาติ</p>
                                        <p className="date">17/08/2021 13:54</p>
                                    </div>
                                </div> */}

                                
                                {
                                    this.state.result_list ? this.state.result_list.map((item, index) => <div key={index} className="li-his">
                                    <div className="grid-his">
                                        <p className="site-used">ใช้ บริการ ซัก อบ พับ สาขา{item.site_name ? item.site_name : ""}</p>
                                        <p className="bag-send">{item.bag_name ? item.bag_name : ""}</p>
                                    </div>
                                    <div className="grid-washer">
                                        <p className="washer-name">Trendy maid {item.washer_name ? item.washer_name : ""}</p>
                                        <p className="date">{item.create_date ? moment(item.create_date).format('DD/MM/YYYY hh:mm') : moment().format('DD/MM/YYYY hh:mm')}</p>
                                    </div>
                                </div>) : null
                                }
                                {/* {
                                    this.state.total_data ?
                                        <div className="cov-page">
                                            <Pagination 
                                            onChange={(page, sizePage) => { this.changePage(page, sizePage) }} 
                                            current={this.state.current} 
                                            total={this.state.total_data} />
                                        </div> : null
                                } */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-bar">
                    <Menu />
                </div>
            </div>
        )
    }
}

export default History;
