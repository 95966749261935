import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Sidebar, Menu } from '../../components/Menu';
import { apiGetProfile } from '../../serveices/api/Profile';

import './scss/submenu.scss'






class Submenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
        imageUrl: [],
        nameuser: "",
        email: "",
        phone: "",
        credit: 0,
        point: 0
    };
}

componentDidMount() {
  this.getProfile();
}

getProfile = async () => {
  let userid = localStorage.getItem("userid")
  let resProfile = await apiGetProfile(userid)
  if (resProfile && resProfile.status) {
    console.log(resProfile)
      if (resProfile.status == 200) {
          this.setState({
              imageUrl: resProfile.data.result.customer_img,
              nameuser: resProfile.data.result.customer_name,
              email: resProfile.data.result.customer_email,
              phone: resProfile.data.result.customer_tel,
              credit: resProfile.data.result.credit,
              point: resProfile.data.result.point
          })

      }
  }
}

  logout = () => {
    localStorage.removeItem("userid");
    setTimeout(() => { window.location.href = "/" }, 300)
  }



  render() {



    return (
      <div className="cover-submenu">
        < Sidebar/>
        <div className="top-menu">
          <div className="container listbox">
            <div className="profile-box">
              <div className="img-profile">
                <img src={this.state.imageUrl} alt="" className="img-fluid img-user" />
              </div>
              <p className="nameuser">{this.state.nameuser ? this.state.nameuser : "- No User Name -"}</p>
              <p className="nameuser">{this.state.phone ? this.state.phone : "- No Phone Number -"}</p>
            </div>
            <div className="list-sub">
            <div className="menu">
                <div className="bx-card">
                  <div className="img-icon-sub">
                    <img src="/img/icon/card.png" alt="" className="img-fluid" />
                  </div>
                 
                  <p className="txt">เครดิต</p>
                  <p className="num">{this.state.credit ? this.state.credit : 0}</p>
                </div>
              </div>
              <div className="menu">
                <div className="bx-card">
                  <div className="img-icon-sub">
                    <img src="/img/icon/coin.png" alt="" className="img-fluid" />
                  </div>
                 
                  <p className="txt">แต้ม</p>
                  <p className="num">{this.state.point ? this.state.point : 0}</p>
                </div>
              </div>
              <div className="menu">
                <a href="/profile" className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/icon/profile.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> โปรไฟล์</p>
                  </a>
              </div>
              <div className="menu">
                <a href="/history" className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/icon/history.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> ประวัติการใช้งาน</p>
                  </a>
              </div>
              <div className="menu">
              <a href="/coupon" className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/icon/coupon.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> คูปอง</p>
                  </a>
              </div>

              <div className="menu">
              <a href="/coupon/history" className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/icon/coupon.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> ประวัติการใช้คูปอง</p>
                  </a>
              </div>


              <div className="menu">
              <a href="/contact" className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/icon/help.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> ติดต่อเรา</p>
                  </a>
              </div>

              <div className="menu">
              <a href="https://new.trendywash.net/home" className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/logo/logo-member.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> Trendywash</p>
                  </a>
              </div>

              
              {/* <div className="menu">
                <a onClick={() => {
                  this.logout();
                }} className="link-page">
                  <div className="img-icon-sub">
                    <img src="/img/icon/logout.png" alt="" className="img-fluid" />
                  </div> 
                  <p className="menu-name"> ออกจากระบบ</p>
                </a>
              </div> */}
            </div>
          </div>
        </div>

        <div className="menu-bar">
          <Menu />
        </div>
      </div>
    )
  }
}

export default Submenu;
