import React, { Component } from 'react';
import { Menu, Sidebar } from '../../components/Menu';
import { Announce } from '../../components/Announce';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useLocation } from "react-router-dom";
import 'antd/dist/antd.css';
import { Carousel, Button, Modal, Rate } from 'antd';
import Swal from "sweetalert2";
import moment from 'moment';
import 'moment/locale/th'

import { apiGetBanner, apiGetOnProcess, apiGetBagFinal, apiRateWasher } from '../../serveices/api/Banner';


import './scss/home.scss'


var site_id;
var user_id;


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: [],
            list_process: [],
            userid: localStorage.getItem("userid"),
            isModalRating: false,
            rating: 1.5,
            transaction_id_rating: 0,
        };
    }



    componentDidMount() {
        user_id = new URLSearchParams(this.props.location.search).get("user_id")
        site_id = new URLSearchParams(this.props.location.search).get("site_id")
        if (user_id) {
            localStorage.setItem("userid", user_id)
        }
        // localStorage.setItem("userid",23129)

        this.getBanner();
        this.getProcess();


    }

    handleChangeRate = value => {
        // console.log(value)
        this.setState({
            rating: value
        });
    };

    showModalRating = () => {
        this.setState({
            isModalRating: true
        })
    }


    closeModalRating = () => {
        this.setState({
            isModalRating: false
        })
    }




    getBanner = async () => {
        let resBanner = await apiGetBanner()
        if (resBanner && resBanner.status) {
            if (resBanner.status === 200) {
                this.setState({
                    banner: resBanner.data.result
                })

            }
        }
    }


    getProcess = async () => {
        let userid = this.state.userid
        let process = await apiGetOnProcess(userid)
        if (process && process.status) {
            if (process.status === 200) {
                this.setState({
                    list_process: process.data.result
                })

            }
        }
    }


    renderStatus = (status_id) => {
        switch (status_id) {
            case 1:
                return "ส่งซัก";
            case 6:
                return "ผ้าของคุณเสร็จแล้ว";
            default:
                return "กำลังดำเนินการ";
        }
    }

    getBag = async (t_id) => {
        let user_id = this.state.userid;
        let getMyBag = await apiGetBagFinal(t_id, user_id)
        if (getMyBag && getMyBag.status) {
            if (getMyBag.status === 200) {
                this.getProcess();
                this.setState({
                    isModalRating: true,
                    transaction_id_rating: t_id
                })

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        }
    }

    selectRating = async () => {
        let giveRate = await apiRateWasher()
        if (giveRate && giveRate.status) {
            if (giveRate.status === 200) {

                this.setState({
                    isModalRating: false,
                })

                Swal.fire({
                    title: 'Success!',
                    text: 'ให้คะแนนสำเร็จ',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })

            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        }
    }


    handleRefresh = () => {
        window.location.reload(false);
    }


    render() {



        return (
            <div className="cover-home">
                <div className="box-bar">
                    <Sidebar />
                </div>
                <div className="box-banner">
                    <Carousel className="banner-promote" autoplay touchMove={false}>
                        <div className="cov-banner">
                            <video width="100%" height="240" autoPlay controls>
                                <source src="/video/cusvdo.mp4" type="video/mp4" />
                            </video>

                        </div>

                        {/* {
                            this.state.banner ? this.state.banner.map((v) => <div key={v.banner_id}>
                                <div className="cov-banner">
                                    {
                                        v.type === "PIC" ? <img height="240" src={v.url} alt="" /> :
                                            <iframe width="100%" height="240" src={v.url}></iframe>
                                    }

                                </div>
                            </div>) : null
                        } */}


                    </Carousel>
                </div>
                <PullToRefresh onRefresh={() => this.handleRefresh()}>
                    <div className="box-announce">
                        <div className="list container">
                            <div className="hd-announce">
                                <h1 className="hd-txt"><img className="img-fluid img-news" src="/img/icon/news.png" /> ข่าวสาร/โปรโมชั่น</h1>
                                <hr className="br-hr" />
                            </div>
                            <Announce />
                        </div>
                    </div>
                    <div className="box-status">
                        <div className="status container">
                            <div className="hd-announce">
                                <h1 className="hd-txt"><img className="img-fluid img-news" src="/img/icon/statuswasher.png" /> สถานะ</h1>
                                <hr className="br-hr" />
                            </div>
                            <div className="timeline">
                                {/* <div key={1} className="cov-grid">
                                <div className="icon-wash">
                                    <img className="img-icon-wash" src="/img/icon/blg.png" />
                                </div>
                                <div className="status-wash">
                                    <div className="cov-txt">
                                        <p className="status-txt">"dd"</p>
                                        <p className="code-wash">"dffds"</p>
                                    </div>
                                    <div className="cov-site">
                                        <div className="box-count">
                                            <p className="num-wash">ถุงที่ 1</p>
                                            <p className="site-wash">สาขา</p>
                                        </div>
                                        <div className="box-user-wash">
                                            <p className="user-wash">Trendy maid สุดา</p>
                                            <p className="site-wash">{moment().format('Do MMM YYYY, h:mm a')}</p>
                                        </div>
                                    </div>

                                </div>
                            </div> */}

                                <div className="cover-btn" >
                                    <Button onClick={() => { window.location.href = '/registerbag' }} className="btn-re">ลงทะเบียนถุง</Button>
                                </div>


                                <div className="cover-btn" >
                                    <Button onClick={() => { window.location.href = `/scanner?site_id=${site_id}` }} className="btn-send">ส่งผ้า</Button>
                                </div>
                                {
                                    this.state.list_process ? this.state.list_process.map((item, index) => <div className="li-task">
                                        <div key={index} className="cov-grid">

                                            <div className="icon-wash">
                                                <img className="img-icon-wash" src={item.bag_size === "L" ? "/img/icon/wlb.png" : "/img/icon/blg.png"} />
                                            </div>
                                            <div className="status-wash">
                                                <div className="cov-txt">
                                                    <p className="status-txt">{item.bag_name ? item.bag_name : "-"}</p>
                                                    <p className="code-wash">{item.status_id ? this.renderStatus(item.status_id) : ""}</p>
                                                </div>
                                                <div className="cov-site">
                                                    <div className="box-count">
                                                        <p className="num-wash">ถุงที่ {index + 1}</p>
                                                        <p className="site-wash">สาขา {item.site_name ? item.site_name : ""}</p>
                                                    </div>
                                                    <div className="box-user-wash">
                                                        <p className="user-wash">Trendy maid {item.washer_name ? item.washer_name : "-"}</p>
                                                        <p className="site-wash">{item.create_date ? moment(item.create_date).format('DD/MM/YYYY, hh:mm') : moment().format('DD/MM/YYYY, hh:mm')}</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        {
                                            item.status_id === 6 ?
                                                <div className="btn-get-bag">
                                                    {console.log(item)}
                                                    <Button onClick={() => {
                                                        this.getBag(item.transaction_id)
                                                    }} className="btn-suc">รับผ้า</Button>
                                                </div> : null
                                        }
                                    </div>) : null
                                }

                            </div>
                        </div>
                    </div>

                </PullToRefresh>

                <Modal
                    title="ให้คะแนน Trendy maid"
                    className="modal-rating"
                    visible={this.state.isModalRating}
                    footer={false}
                    onCancel={() => this.closeModalRating()}
                >
                    <div className="bx-rating">
                        <Rate allowHalf
                            onChange={(e) => { this.handleChangeRate(e) }}
                            value={this.state.rating ? this.state.rating : 0}
                            defaultValue={this.state.rating} />
                    </div>

                    <div className="cov-btn-action">
                        <Button className="btn-cancel"
                            onClick={() => this.closeModalRating()}
                        >ยกเลิก</Button>
                        <Button className="btn-ok"
                            onClick={() => this.selectRating()}
                        >ยืนยัน</Button>
                    </div>

                </Modal>



                <div className="menu-bar">
                    <Menu />
                </div>
            </div>
        )
    }
}

export default Home;
