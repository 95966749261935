import axios from '../../config/axios';

const apiMyCoupon = async (userid) => {

    try {
        const result = await axios.get(`/washer/coupon/user?user_id=${userid}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiCouponPrice = async (siteId) => {

    try {
        const result = await axios.get(`/washer/coupon/price?site_id=${siteId}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiCouponBuy = async (data) => {

    try {
        const result = await axios.post(`/washer/coupon/buy`,data)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiHisCoupon = async (userid) => {

    try {
        const result = await axios.get(`/washer/coupon/history?user_id=${userid}`)
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

export {
    apiMyCoupon,
    apiCouponPrice,
    apiCouponBuy,
    apiHisCoupon
}