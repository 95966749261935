import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from "sweetalert2";


import { apiForgetPassword, apiLogin } from '../../serveices/api/Login';
import { Modal, Input, Button } from 'antd';
// import { getToken } from '../../firebase';
// import { getToken, onMessageListener } from '../../firebase';


import 'antd/dist/antd.css';
import './scss/login.scss'




class Login extends Component {

    

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            isModalVisible: false,
            email: "",
            show: false
        };
    }

    

    componentDidMount(){
        // onMessageListener().then(message => {
        //     this.setState({
        //         show: true
        //     })
        //   }).catch(err => console.log('failed: ', err));
    }

    

    

    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
      };



    authUser = () => {

        let data = new FormData();
        data.append('customer_tel', this.state.username)
        data.append('customer_password', this.state.password)
        data.append('customer_fb_id', "")
        data.append('customer_email', "")
        data.append('customer_firebase_token', "")
        data.append('action', "login")
        data.append('customer_token', "")
        data.append('login_channel', "mobile")


        this.tokenLogin(data);


    }

    sendEmail = async () => {
        let email = this.state.email
        let sendmail = await apiForgetPassword(email)
        if (sendmail && sendmail.status) {
            if (sendmail.status == 200) {
                Swal.fire({
                    title: 'Succcess!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })

                this.setState({
                    isModalVisible: false
                })

            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: sendmail.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })

            this.setState({
                isModalVisible: false
            })
        }

    }

    tokenLogin = async (data) => {
        let resToken = await apiLogin(data)
        if (resToken && resToken.status) {
            if (resToken.status == 200) {
                console.log(resToken)
                localStorage.setItem("userid",resToken.data.detail.customer_id)
                // localStorage.setItem("token", resToken.data.result);
                // localStorage.setItem("name", resToken.data.customer_name);

                Swal.fire({
                    title: 'Succcess!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })

                setTimeout(() => { window.location.href = "/home" }, 1000)

            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: resToken.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1000
            })
        }

    }





    render() {



        return (
            <div className="cover-login">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="box">
                                    <img className="img-fluid" src="/img/logo/logonew.png" />
                                    {/* <p className="text-muted"> Please enter your login and password!</p> */}
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            this.setState({
                                                username: e.target.value
                                            })
                                        }}
                                        placeholder="Tel" />
                                    <input
                                        type="password"
                                        onChange={(e) => {
                                            this.setState({
                                                password: e.target.value
                                            })
                                        }}
                                        placeholder="Password" />
                                    <a className="forgot text-muted" onClick={()=>{this.setState({isModalVisible: true})}}>Forgot password?</a>
                                    <button onClick={() => {
                                        this.authUser();
                                    }} className="btn-login-sub" >
                                        Login
                                    </button>
                                    <a className="register text-link" href="/register">Don't have an account in the system? Register</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="Forget Password" visible={this.state.isModalVisible} footer={false} onCancel={()=>{this.handleCancel()}}>
                        <div className="data">
                            <Input className="email" value={this.state.email} onChange={(e)=>{this.setState({email: e.target.value})}} />
                        </div>
                        <div className="btn-sub">
                            <Button onClick={()=>{this.sendEmail()}}>Submit</Button>
                        </div>
                </Modal>
            </div>
        )
    }
}

export default Login;
