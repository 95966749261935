// src/routes.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from "react-router";
 
import {Login} from './pages/Login';
import {Register, RegisterBag, Scanner} from './pages/Register';
import {Home} from './pages/Home';
import {Profile} from './pages/Profile';
import {Contact} from './pages/Contact';
import {History} from './pages/History';
import {Topup} from './pages/Topup';
import {Submenu} from './components/Menu';
import {Coupon, HistoryCoupon} from './pages/Coupon';
import Loading from './pages/Loading';









export default () => {
    return (
        <BrowserRouter>
                    <Switch>
                        <Route exact path={'/'} component={Login} />
                        <Route exact path={'/register'} component={Register} />
                        <Route exact path={'/home'} component={Home} />
                        <Route exact path={'/profile'} component={Profile} />
                        <Route exact path={'/contact'} component={Contact} />
                        <Route exact path={'/history'} component={History} />
                        <Route exact path={'/registerbag'} component={RegisterBag} />
                        <Route exact path={'/topup'} component={Topup} />
                        <Route exact path={'/scanner'} component={Scanner} />
                        <Route exact path={'/submenu'} component={Submenu} />
                        <Route exact path={'/coupon'} component={Coupon} />
                        <Route exact path={'/coupon/history'} component={HistoryCoupon} />
                        <Route exact path={'/loading/:id'} component={Loading} />

                        {/* <Route component={NotFound} /> */}
                    </Switch>
        </BrowserRouter>
    )

}
