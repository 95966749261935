import React, { Component } from 'react';
import { Sidebar, Menu } from '../../components/Menu';
import 'antd/dist/antd.css';
import { Tabs, Button, Modal, Select } from 'antd';
import { apiMyCoupon, apiCouponPrice, apiCouponBuy } from '../../serveices/api/Coupon';
import { apiListSite } from '../../serveices/api/Scan';
import Swal from "sweetalert2";
import moment from 'moment';
import 'moment/locale/th'


import './scss/coupon.scss'


const { TabPane } = Tabs;
const { Option } = Select;



class Coupon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            isModalPolicy: false,
            listMyCoupon: [],
            amountCoupon: 1,
            pricePack: 149,
            listCouponBuy: {},
            couponSelect: {},
            siteId: 0,
            siteList: [],
        };
    }

    componentDidMount() {
        this.getListMy();
        this.getSiteList();
    }

    getSiteList = async () => {
        let list = await apiListSite()
        if (list.status === 200) {
            this.setState({
                siteList: list.data.result,
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
            })
        }
    }

    getListMy = async () => {
        let userid = localStorage.getItem("userid")
        let list = await apiMyCoupon(userid)
        if (list.status === 200) {
            this.setState({
                listMyCoupon: list.data.result
            })
        }
    }

    showModalPolicy = () => {
        this.setState({
            isModalPolicy: true
        })
    };

    closeModalPolicy = () => {
        this.setState({
            isModalPolicy: false
        })
    };



    getListCoupon = async (siteId) => {
        let listcoupon = await apiCouponPrice(siteId)
        if (listcoupon.status === 200) {
            this.setState({
                listCouponBuy: listcoupon.data.result
            })
        }
    }


    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    };

    handleOk = async () => {
        let userid = parseInt(localStorage.getItem("userid"))
        let data = {
            user_id: userid,
            price_id: this.state.couponSelect.price_id
        }
        let buyCoupon = await apiCouponBuy(data)
        if (buyCoupon.status === 200) {
            Swal.fire({
                title: 'Success!',
                text: 'ซื้อคูปองสำเร็จ',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })

            this.getListMy();
            // window.location.reload(false);
        } else {
            Swal.fire({
                title: 'Error!',
                text: buyCoupon.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
            })
        }
        this.setState({
            isModalVisible: false
        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    };

    onChange = (value) => {
        this.setState({
            amountCoupon: value
        })
    }


    onChangeSelect = (e) => {
        console.log(`selected ${e}`);
        this.setState({
          siteId: e
        })

        this.getListCoupon(e);
      }






    render() {



        return (
            <div className="cover-home">
                <div className="box-bar">
                    <Sidebar />
                </div>

                <div className="box-coupon">
                    <div className="br-top">
                        <div className="cov-coupon">
                            <div className="hd-coupon">
                                <h3 className="txt">
                                    {/* <img className="img-fluid" src="/img/Icon/coupon.png" />  */}
                                    คูปอง</h3>
                                <hr />
                            </div>

                            <div className="cov-tab">
                                <Tabs defaultActiveKey="1" type="card" size="default">
                                    <TabPane tab="ซื้อคูปอง" key="1">
                                        <div className="dropdown-site">
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="โปรดเลือกสาขาที่ต้องการ"
                                                value={this.state.siteId ? parseInt(this.state.siteId) : null}
                                                defaultValue={(this.state.siteId != 0 || this.state.siteId != null) ? this.state.siteId : null}
                                                optionFilterProp="children"
                                                onChange={(e) => this.onChangeSelect(e)}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    this.state.siteList ? this.state.siteList.map((v, i) => <Option key={i} value={v.site_id}>{v.site_name}</Option>) : null
                                                }
                                            </Select>
                                        </div>
                                        <div className="bx-m-coupon">
                                            {
                                                this.state.listCouponBuy && this.state.listCouponBuy.L ? <>
                                                    <div className="show-hd">
                                                        <div className="cov-icon">
                                                            <img src="/img/icon/l.png" className="img-fluid" />
                                                        </div>
                                                        <p className="txt-des">แพ็คเกจ Size L</p>
                                                    </div>
                                                    <div className="list-coupon">
                                                        {
                                                            this.state.listCouponBuy.L && this.state.listCouponBuy.L.length ? this.state.listCouponBuy.L.map((v, i) => <div className="bx-grid" key={i} onClick={() => { this.setState({ isModalVisible: true, couponSelect: v }) }}>
                                                                <div className="icon-size">
                                                                    <img src="/img/icon/twm.png" alt="" className="img-fluid" />
                                                                </div>
                                                                <div className="detail">
                                                                    <div className="name-pack">
                                                                        <p className="name">แพค {v.time} คร้ัง</p>
                                                                        <p className="save">SAVE {Math.floor((((v.price_origin * v.time) - v.price) / v.price) * 100)}%</p>
                                                                    </div>
                                                                    <div className="des-pack">
                                                                        <p className="price">{v.price} บาท (จาก {v.price_origin * v.time} บาท)</p>
                                                                        <p className="time">ใช้ได้ในระยะเวลา {v.duration} วัน</p>
                                                                    </div>
                                                                </div>
                                                            </div>) : null
                                                        }

                                                    </div>

                                                </> : null
                                            }

                                        </div>
                                        <hr />
                                        <div className="bx-m-coupon">

                                            {
                                                this.state.listCouponBuy && this.state.listCouponBuy.M ? <>
                                                    <div className="show-hd">
                                                        <div className="cov-icon">
                                                            <img src="/img/icon/m.png" className="img-fluid" />
                                                        </div>
                                                        <p className="txt-des">แพ็คเกจ Size M</p>
                                                    </div>
                                                    <div className="list-coupon">
                                                        {
                                                            this.state.listCouponBuy.M && this.state.listCouponBuy.M.length ? this.state.listCouponBuy.M.map((v, i) => <div className="bx-grid" key={i} onClick={() => { this.setState({ isModalVisible: true, couponSelect: v }) }}>
                                                                <div className="icon-size">
                                                                    <img src="/img/icon/twm.png" alt="" className="img-fluid" />
                                                                </div>
                                                                <div className="detail">
                                                                    <div className="name-pack">
                                                                        <p className="name">แพค {v.time} คร้ัง</p>
                                                                        <p className="save">SAVE {Math.floor(((v.price_origin - v.price) / v.price) * 100)}%</p>
                                                                    </div>
                                                                    <div className="des-pack">
                                                                        <p className="price">{v.price} บาท (จาก {v.price_origin} บาท)</p>
                                                                        <p className="time">ใช้ได้ในระยะเวลา {v.duration} วัน</p>
                                                                    </div>
                                                                </div>
                                                            </div>) : null
                                                        }

                                                    </div>

                                                </> : null
                                            }
                                        </div>
                                        {/* <hr />
                                        <div className="bx-m-coupon">
                                            {
                                                this.state.listCouponBuy && this.state.listCouponBuy.S ? <>
                                                    <div className="show-hd">
                                                        <div className="cov-icon">
                                                            <img src="/img/icon/l.png" className="img-fluid" />
                                                        </div>
                                                        <p className="txt-des">ใส่ผ้าได้ 5 ชิ้น</p>
                                                    </div>
                                                    <div className="list-coupon">
                                                        {
                                                            this.state.listCouponBuy.S && this.state.listCouponBuy.S.length ? this.state.listCouponBuy.S.map((v, i) => <div className="bx-grid" key={i} onClick={() => { this.setState({ isModalVisible: true, couponSelect: v }) }}>
                                                                <div className="icon-size">
                                                                    <img src="/img/icon/twm.png" alt="" className="img-fluid" />
                                                                </div>
                                                                <div className="detail">
                                                                    <div className="name-pack">
                                                                        <p className="name">แพค {v.time} คร้ัง</p>
                                                                        <p className="save">SAVE {Math.floor(((v.price_origin - v.price) / v.price) * 100)}%</p>
                                                                    </div>
                                                                    <div className="des-pack">
                                                                        <p className="price">{v.price} บาท (จาก {v.price_origin} บาท)</p>
                                                                        <p className="time">ใช้ได้ในระยะเวลา {v.duration} วัน</p>
                                                                    </div>
                                                                </div>
                                                            </div>) : null
                                                        }

                                                    </div>

                                                </> : null
                                            }

                                        </div> */}
                                        <div className="note">
                                            <h3 className="title-note">หมายเหตุ</h3>
                                            <p className="des-note">- ถุงไซส์ M ผ้าบางไม่เกิน 20 ตัว หรือ ผ้าหนากางเกงยีนส์ ไม่เกิน 8 ตัว หรือ ผ้าห่มขนาดไม่เกิน 5 ฟุต</p>
                                            <p className="des-note">- ถุงไซส์ L ผ้าบางไม่เกิน 30 ตัว หรือ ผ้าหนากางเกงยีนส์ ไม่เกิน 10 ตัว หรือ ผ้าห่มขนาดไม่เกิน 6 ฟุต</p>

                                        </div>

                                        <div className="bg-policy">
                                            <p className="txt-policy" onClick={() => { this.showModalPolicy() }}>เงื่อนไขการใช้บริการ</p>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="คูปองของฉัน" key="2">
                                        <div className="list-my-coupon">
                                            {
                                                this.state.listMyCoupon && this.state.listMyCoupon.length ? this.state.listMyCoupon.map((v, i) => <div key={i} className="bx-grid">
                                                    <div className="icon-size">
                                                        <img src={v.bag_size === "L" ? "/img/icon/twl.png" : "/img/icon/twm.png"} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="detail">
                                                        <p className="type-coupon">คูปอง Size {v.bag_size}</p>
                                                        <p className="time">เหลือระยะเวลา {moment(v.end_date).diff(moment(), "days")} วัน</p>
                                                    </div>
                                                </div>) : null
                                            }

                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="menu-bar">
                    <Menu />
                </div>

                <Modal
                    title="เงื่อนไขการใช้บริการ"
                    className="modal-policy"
                    visible={this.state.isModalPolicy}
                    footer={false}
                    onCancel={() => this.closeModalPolicy()}
                >
                    <div className="ul-policy">
                        <p className="li-policy">1. กรณีเกิดความเสียหายกรุณาแจ้ง Line @ ภายใน 24 ชม. พร้อมแจ้งรหัส+รูปภาพความเสียหาย</p>
                        <p className="li-policy">2. สงวนสิทธิ์ในการรับผิดชอบความเสียหายกรณีไม่มารับผ้าภายใน 24 ชม.</p>
                        <p className="li-policy">3. ไม่รับผิดชอบกรณีผ้าสีตก การหดตัวของผ้า</p>
                        <p className="li-policy">4. ในกรณีเกิดความเสียหายต่อผ้าที่นำมาใช้บริการ ทางบริษัทจะรับผิดชอบ ไม่เกินจำนวน 2 เท่าของค่าบริการที่ลูกค้าใช้ในครั้งนั้นๆ</p>
                    </div>

                </Modal>



                <Modal className="modal-package" title={`แพค ${this.state.couponSelect.time} คร้ัง`} visible={this.state.isModalVisible} onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                >
                    <p className="price-pack">ราคา {this.state.couponSelect.price} บาท (จาก {(this.state.couponSelect.price_origin * this.state.couponSelect.time)} บาท)</p>

                    {/* <div className="amount">
                        <div className="grid-amount">
                                <div className="btn-minus">
                                    <Button onClick={()=>{
                                        if(this.state.amountCoupon <= 1){
                                            this.setState({
                                                amountCoupon: 1
                                            })
                                        }else{
                                            this.setState({
                                                amountCoupon: this.state.amountCoupon-1
                                            })
                                        }
                                    }}>-</Button>
                                </div>
                                <div className="input-amount">
                                    <InputNumber min={1} max={100} value={this.state.amountCoupon} onChange={(value) => this.onChange(value)} />
                                </div>
                                <div className="btn-add">
                                    <Button onClick={()=>{
                                        if(this.state.amountCoupon >= 100){
                                            this.setState({
                                                amountCoupon: 100
                                            })
                                        }else{
                                            this.setState({
                                                amountCoupon: this.state.amountCoupon+1
                                            })
                                        }
                                        
                                    }}>+</Button>
                                </div>
                        </div>
                    </div> */}

                    <p className="price-total">รวมทั้งหมด {this.state.couponSelect.price} บาท</p>
                    <div className="cov-btn-action">
                        <Button className="btn-cancel"
                            onClick={() => this.handleCancel()}
                        >ยกเลิก</Button>
                        <Button className="btn-ok"
                            onClick={() => this.handleOk()}
                        >ยืนยัน</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Coupon;
